import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import './most-popular.css';
// import PopularList from './PopularList';
// import ProductListing from './ProductListing';
import BannerSlider from './Banner-Slider';
import WatchlistShoes1 from '../assets/images/WatchlistShoes1.png'
import axios from 'axios';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import $ from 'jquery';
import moment from 'moment-timezone';

class MostPopular extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            productssmall: [],
            isLoading: false,
            most_likes: localStorage.getItem('search_most_popular') ? localStorage.getItem('search_most_popular') == 'weekly' ? 'week' : localStorage.getItem('search_most_popular') == 'monthly' ? 'month' : localStorage.getItem('search_most_popular') == 'six_month' ? '6month' : localStorage.getItem('search_most_popular') == 'all_time' ? 'all_time' : 'week' : 'week',
            brand_id: 0,
            perpage: 15,
            skip: 10,
            totalLeft: 0,
            currentPage: 1,
            isbuttonLoading: false,
            width: window.innerWidth,
        };
        this.getProducts = this.getProducts.bind(this);
        this.changefilter = this.changefilter.bind(this);
        this.changefilterbrand = this.changefilterbrand.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        let d = new Date();
        document.title = "Most Popular Sneakers " + d.getFullYear();
        localStorage.removeItem('search_most_popular')
        if (this.state.width <= 767) {
            this.setState({ perpage: 20, skip: 0, products: [] }, () => { this.getProducts(); this.getProductsAnother(); });
        }
        else if (this.state.width >= 768 && this.state.width <= 1200) {
            $('#product_listing .allproducts .product-list:lt(4)').addClass('product-list-week-place');
            this.setState({ perpage: 10, skip: 10 }, () => { this.getProducts(); this.getProductsAnother(); });

        }
        else {
            this.setState({ perpage: 15, skip: 10 }, () => { this.getProducts(); this.getProductsAnother(); });
        }

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        // this.getProductsAnother();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.width >= 768 && this.state.width <= 1200) {
            $('#product_listing .allproducts .product-list:lt(4)').addClass('product-list-week-place');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        if (this.state.width === window.innerWidth) {
            return false;
        }
        this.setState({ width: window.innerWidth }, () => {
            if (this.state.width <= 767) {
                this.setState({ perpage: 20, skip: 0, products: [] }, () => { this.getProducts(); this.getProductsAnother(); });
            }
            else if (this.state.width >= 768 && this.state.width <= 1200) {
                $('#product_listing .allproducts .product-list:lt(4)').addClass('product-list-week-place');
                this.setState({ perpage: 10, skip: 10, productssmall: [], currentPage: 1 }, () => { console.log("prod" + this.state.productssmall.length); this.getProducts(); this.getProductsAnother(); });
            }
            else if (this.state.width > 1200) {
                this.setState({ perpage: 15, skip: 10, productssmall: [], currentPage: 1 }, () => { this.getProducts(); this.getProductsAnother(); });
            }
            else {

            }
        });
    }

    like(id) {
        axios({
            method: "get",
            url: APICONST.LIKES(id),
            headers: { "content-type": "application/json" }
        })
            .then(response => {
                if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
                    let likeCount = parseInt($('#' + id + '-like-hide').text()) + 1;
                    let likeCountShow = COMMONCONST.makeFriendly(likeCount);
                    $('#' + id + '-like-hide').text(likeCount);
                    $('#' + id + '-like').text(likeCountShow);
                    $('#' + id + '-like').parent('li').addClass('active');
                } else {
                    let likeCount = parseInt($('#' + id + '-like-hide').text()) - 1;
                    let likeCountShow = COMMONCONST.makeFriendly(likeCount);
                    $('#' + id + '-like-hide').text(likeCount);
                    $('#' + id + '-like').text(likeCountShow);
                    $('#' + id + '-like').parent('li').removeClass('active');
                }
            })
            .catch(error => {
            });
    }

    watchlist(id) {
        COMMONCONST.setCookie('watchlist', true);
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.WATCHLIST(id),
            headers: headers,
            params: { 'watchlist-cookie': COMMONCONST.getCookie('watchlist') }
        })
            .then(response => {
                if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
                    let watchCount = COMMONCONST.makeFriendly(parseInt($('#' + id + '-watchlist-hide').text()) + response.data.result.add);
                    $('#' + id + '-watchlist').text(watchCount);
                    $('#' + id + '-watchlist').parent('li').addClass('active');
                } else {
                    $('#' + id + '-watchlist').parent('li').removeClass('active');
                    if (this.state.isWatchList === 1) {
                        this.getProducts(true, this.props);
                        // $('#' + id + '-watchlist').closest('.product-list').remove();
                        // if ($('.allproducts').find('.product-list').length == 0) {
                        //     // this.setState({ products: [], productsByYear: [], productsYearKeys: []})
                        // }
                    }
                }
            })
            .catch(error => {
            });
    }

    getProducts() {
        this.setState({
            isLoading: true
        });
        let brand_id = ''
        if (this.state.brand_id != 0) {
            brand_id = this.state.brand_id;
        }
        let paramsValues = {
            cookie: COMMONCONST.getCookie('watchlist'),
            most_likes: this.state.most_likes,
            most_popular: 1,
            brand_id: brand_id
        }
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.PRODUCTLISTING,
            headers: headers,
            params: paramsValues
        })
            .then(response => {
                this.setState({ products: response.data.result.data.data, isLoading: false });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                } else {
                    this.setState({ error: error.message, isLoading: false })
                }
            }
            );
    }

    getMoreProducts() {
        let page = this.state.currentPage + 1;
        this.setState({
            currentPage: page
        }, () => this.getProductsAnother(1));

    }
    getProductsAnother(add = 0) {
        this.setState({
            isbuttonLoading: true
        });
        let brand_id = ''
        if (this.state.brand_id != 0) {
            brand_id = this.state.brand_id;
        }
        let paramsValues = {
            search: this.state.most_likes,
            brand_id: brand_id,
            perpage: this.state.perpage,
            skip: this.state.skip,
            page: this.state.currentPage
        }
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.POPULAR_LIST,
            headers: headers,
            params: paramsValues
        })
            .then(response => {
                let meta = response.data.result.data.meta;
                let totalLeft = meta.total - (meta.page * meta.perpage);
                totalLeft = (totalLeft < 0) ? 0 : totalLeft;
                let products = [];
                if (add === 1) {
                    products = this.state.productssmall;
                }
                if (response.data.result.data.data.length > 0) {
                    products.push(response.data.result.data.data);
                }
                console.log(this.state.currentPage);
                console.log(products);
                this.setState({ productssmall: products, isbuttonLoading: false, totalLeft: totalLeft });
                if (totalLeft > 0 && this.state.currentPage == 1) {
                    $('.load-more').trigger('click');
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                } else {
                    this.setState({ error: error.message, isbuttonLoading: false })
                }
            }
            );
    }



    changefilter(data) {
        this.setState({ most_likes: data, currentPage: 1 }, () => {
            this.getProducts();
            this.getProductsAnother();
        });
    }

    showDate(item) {
        if (typeof item.releaseDay != 'string' || typeof item.releaseMonth != 'string' || typeof item.releaseYear != 'string') {
            return '<p> Release Date N/A </p>';
        }
        if (item.releaseYear && item.releaseMonth && item.releaseDay && item.releaseDay !== '00') {
            let currentD = moment.tz(item.releaseYear + '-' + item.releaseMonth + '-' + item.releaseDay + ' 00:00:00', 'America/New_York');
            let curr = moment().tz("America/New_York");
            // console.log(currentD);
            // console.log(moment().tz("America/New_York").format());
            // console.log(curr.isSameOrAfter(currentD))
            if (curr.isSameOrAfter(currentD)) {
                return '<p style="color:#707070">Released on ' + item.releaseMonth + '/' + item.releaseDay + '/' + item.releaseYear + '</p>';
            } else {
                return '<p>Available ' + item.releaseMonth + '/' + item.releaseDay + '/' + item.releaseYear + '</p>';
            }
        } else if (item.releaseYear && item.releaseMonth && item.releaseMonth !== '00') {
            return '<p>Expected ' + moment(item.releaseMonth, 'MM').format('MMMM') + ' ' + item.releaseYear + '</p>';
        } else {
            return '<p>Expected ' + item.releaseYear + '</p>';
        }
    }


    changefilterbrand(data) {
        this.setState({ brand_id: data, currentPage: 1 }, () => {
            this.getProducts();
            this.getProductsAnother();
        });
    }
    render() {

        let fireIcon = (
            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="16.145px" height="21.492px" viewBox="0 0 16.145 21.492" enableBackground="new 0 0 16.145 21.492">
                <g>
                    <defs>
                        <rect id="SVGID_1_" y="0" width="16.145" height="21.492" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" overflow="visible" />
                    </clipPath>
                    <path fill="none" stroke="#737373" strokeWidth="2" strokeMiterlimit="10" d="M15.139,11.8
                        c0.163,4.954-3.336,8.691-7.18,8.691C4.116,20.492,1,17.376,1,13.532C1,8.778,6.11,4.801,8.187,2.359
                        c0.19,1.43,0.229,5.68,1.517,7.691C10.775,11.726,13.326,12.243,15.139,11.8z"/>
                </g>
            </svg>

        )

        let viewIcon = (
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
                <path d="M8.19327 9.8278C4.34185 9.8278 1.34774 5.71358 1.21785 5.54215L0.977539 5.1993L1.22434 4.85645C1.34774 4.6789 4.34185 0.570801 8.19977 0.570801C12.0512 0.570801 15.0453 4.68502 15.1752 4.85645L15.422 5.1993L15.1752 5.54215C15.0453 5.7197 12.0512 9.8278 8.19327 9.8278ZM2.56877 5.1993C3.32866 6.12378 5.60834 8.60333 8.19327 8.60333C10.7782 8.60333 13.0579 6.12378 13.8178 5.1993C13.0579 4.27483 10.7782 1.79527 8.19327 1.79527C5.60834 1.79527 3.32866 4.27483 2.56877 5.1993Z" fill="#707070" />
                <path d="M8.19321 8.19923C6.4396 8.19923 5.01074 6.85232 5.01074 5.19929C5.01074 3.54626 6.4396 2.19934 8.19321 2.19934C9.94681 2.19934 11.3757 3.54626 11.3757 5.19929C11.3757 6.85232 9.9533 8.19923 8.19321 8.19923ZM8.19321 3.42381C7.15403 3.42381 6.30971 4.21971 6.30971 5.19929C6.30971 6.17886 7.15403 6.97477 8.19321 6.97477C9.23238 6.97477 10.0767 6.17886 10.0767 5.19929C10.0767 4.21971 9.23238 3.42381 8.19321 3.42381Z" fill="#707070" />
            </svg>
        )

        const formatDateIndividual = (day, month, yea) => {
            if (typeof yea != 'string' || typeof month != 'string' || typeof day != 'string') {
                return '';
            }
            let year;
            if (yea && month && day && day !== '00') {
                year = yea + '-' + month + '-' + day;
            } else if (yea && month && month !== '00') {
                year = yea + '-' + month;
            } else {
                year = yea;
            }

            if (year.length <= 4) {
                return year;
            } else {
                let mydate = new Date(year);
                let currentD = moment.tz(year + ' 00:00:00', 'America/New_York');
                if (year.length == 7) {
                    // return mydate.toLocaleString('en-us', { month: 'long' }) + ', ' + mydate.getFullYear();
                    currentD = moment.tz(year + '-01 00:00:00', 'America/New_York');
                    return moment(currentD).format('MMMM') + ', ' + moment(currentD).format('YYYY');
                } else {
                    // return mydate.toLocaleString('en-us', { month: 'long' }) + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
                    return moment(currentD).format('MMMM') + ' ' + moment(currentD).format('DD') + ', ' + moment(currentD).format('YYYY');
                }
            }
        }

        const productSmallBlock = (item, key, subkey) => {
            return <div key={Math.random()} className='prod-list-new'>
                <div className="item-list-counter">
                    <span className="item-number">{(subkey * (this.state.perpage)) + this.state.skip + key + 1}</span>
                    {/* <span className="item-rank"><i className="fa fa-caret-down"></i> 2</span> */}
                    {item.current_rank < parseInt(item.previous_rank) ?
                        <>{(parseInt(item.previous_rank) - item.current_rank) <= 999 ?
                            <span className="item-rank rank-positive"> {/* add class .rank-positive and .rank-negative */}
                                <i className="fa fa-caret-up"></i> {parseInt(item.previous_rank) - item.current_rank}
                            </span>
                            : <span className="item-rank">
                                <span className="rank-item-new">new</span>
                            </span>}
                        </>
                        : ''}
                    {item.current_rank > parseInt(item.previous_rank) ?
                        <>{(item.current_rank - parseInt(item.previous_rank)) <= 999 ?
                            <span className="item-rank rank-negative"> {/* add class .rank-positive and .rank-negative */}
                                <i className="fa fa-caret-down"></i> {item.current_rank - parseInt(item.previous_rank)}
                            </span>
                            : <span className="item-rank">
                                <span className="rank-item-new">new</span>
                            </span>}
                        </>
                        : ''}

                    {item.current_rank == parseInt(item.previous_rank) ?
                        <span className="item-rank">
                            -
                        </span>
                        : ''}

                    {item.current_rank && !item.previous_rank ?
                        <span className="item-rank">
                            <span className="rank-item-new">new</span>
                        </span>
                        : ''}
                </div>
                <div className="item-image">
                    <Link target="_blank" to={'/' + item.slug}>
                        <img src={item.product_thumbnail} alt="Thumb" />
                    </Link>
                </div>
                <div className="item-name">
                    <Link target="_blank" to={'/' + item.slug}>
                        {item.product_name}
                        {/* <span className="item-nickname"> "nick" </span> */}
                        {item.product_nickname && <span className="item-nickname"> “{item.product_nickname}”</span>}
                        <span className="item-time" dangerouslySetInnerHTML={{ __html: this.showDate(item) }} ></span>
                    </Link>
                </div>
            </div>
        }

        const productBlock = (item, key) => {
            return <li id={key} key={Math.random()} className={key == 0 || key == 1 ? "product-list product-list-week-place" : "product-list"}>
                <div className="product-item">
                    <div className="prod-list-img">
                        <div className="prod-rank-counter">
                            <div className="prod-list-counter">
                                <span className="item-number">{key + 1}
                                    {key == 0 ? <span>st</span> : ''}
                                    {key == 1 ? <span>nd</span> : ''}
                                    {/* {key == 2 ? <span>rd</span> : ''}
                                    {key > 2 ? <span>th</span> : ''} */}
                                </span>
                                <p className="display-text-none">
                                    {this.state.most_likes == 'week' ? 'place this week' : this.state.most_likes == 'month' ? 'place this month ' : this.state.most_likes == '6month' ? 'place past 6 months' : 'place all time'}
                                </p>
                            </div>
                            {/* <span className="item-rank rank-positive"><i className="fa fa-caret-down"></i>12</span> */}
                            {item.current_rank < parseInt(item.previous_rank) ?
                                <>{(parseInt(item.previous_rank) - item.current_rank) <= 999 ?
                                    <span className="item-rank rank-positive"> {/* add class .rank-positive and .rank-negative */}
                                        <i className="fa fa-caret-up"></i> {parseInt(item.previous_rank) - item.current_rank}
                                    </span>
                                    : <span className="item-rank">
                                        <span className="rank-item-new">new</span>
                                    </span>}
                                </>
                                : ''}
                            {item.current_rank > parseInt(item.previous_rank) ?
                                <>{(item.current_rank - parseInt(item.previous_rank)) <= 999 ?
                                    <span className="item-rank rank-negative"> {/* add class .rank-positive and .rank-negative */}
                                        <i className="fa fa-caret-down"></i> {item.current_rank - parseInt(item.previous_rank)}
                                    </span>
                                    : <span className="item-rank">
                                        <span className="rank-item-new">new</span>
                                    </span>}
                                </>
                                : ''}

                            {item.current_rank == parseInt(item.previous_rank) ?
                                <span className="item-rank">
                                    -
                                </span>
                                : ''}

                            {item.current_rank && !item.previous_rank ?
                                <span className="item-rank">
                                    <span className="rank-item-new">new</span>
                                </span>
                                : ''}
                        </div>
                        <Link target="_blank" to={'/' + item.slug} className="product-img">
                            <img src={item.product_thumbnail} alt="img" />
                        </Link>
                    </div>
                    <div className="prod-list-new-cnt">
                        <div className="prod-details">
                            <h4 className="prod-name"><Link target="_blank" to={'/' + item.slug}>{item.full_name} {item.product_nickname && <span className="item-nickname"> “{item.product_nickname}”</span>}</Link></h4>
                            <h4 className="prod-date">{formatDateIndividual(item.releaseDay, item.releaseMonth, item.releaseYear)}</h4>
                            <h2 className="prod-price">{item.price}</h2>
                        </div>
                        <div className="infos">
                            <ul className="info-count">
                                <li data-toggle="tooltip" data-placement="top">
                                    <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Views</div></div>
                                    <i className="eye-icon">{viewIcon}</i> {COMMONCONST.makeFriendly(item.views_count)}
                                </li>
                                <li title="Likes" className={item.is_liked === 1 ? 'active' : ''}>
                                    <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Likes</div></div>
                                    <i onClick={() => this.like(item.id)} className="fire">{fireIcon}</i> <span id={item.id + "-like"}> {COMMONCONST.makeFriendly(item.likes_counts)} </span>
                                    <span id={item.id + "-like-hide"} className="hide"> {item.likes_counts} </span>
                                </li>
                                <li title="Watchlist" className={item.is_watchlisted === 1 ? 'active' : ''}>
                                    <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Watchlist</div></div>
                                    <i onClick={() => this.watchlist(item.id)} className="fa fa-bookmark-o"></i> <span id={item.id + "-watchlist"}>{COMMONCONST.makeFriendly(item.watch_list_count)}</span>
                                    <span id={item.id + "-watchlist-hide"} className="hide"> {item.watch_list_count} </span>
                                </li>
                            </ul>
                        </div>
                        <div className="prod-detail-cnt">
                            <p>{item.show_genders}</p>
                            <p>{item.sku}</p>
                            <p>{item.show_regions}</p>
                        </div>
                    </div>
                </div>
            </li>
        }

        return (

            <div className='App'>
                <div className="most-popular-main">

                    <BannerSlider />

                    <div className="most-popular-wrap">

                        <div className="container pad-50-blk">
                            <div className={this.state.isLoading === true ? "most-popular-wrap-blk sticky-loader-active" : "most-popular-wrap-blk"}>
                                {this.state.isLoading === true ? <div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div> :
                                    <div>
                                        <div className="product-blk-main">
                                            <div className="product-blk-wrap">
                                                <div className="back-to-home">
                                                    <Link to='/'>Back to Home</Link>
                                                </div>
                                                <div className="popular-prod-blk">
                                                    <div className="popular-product-lisitng-main">
                                                        <div className="popular-prod-time-filter">
                                                            {this.state.width >= 768 ? (
                                                                <h3 className="prod--main-title">Most Popular Sneakers</h3>
                                                            ) : (
                                                                <h3 className="prod--main-title">Most Popular</h3>
                                                            )}
                                                            <ul className="popular-product-lisitng-filter">
                                                                <li onClick={() => this.changefilter('week')} className={this.state.most_likes == 'week' ? "active" : ""}>1w</li>
                                                                <li onClick={() => this.changefilter('month')} className={this.state.most_likes == 'month' ? "active" : ""}>1m</li>
                                                                <li onClick={() => this.changefilter('6month')} className={this.state.most_likes == '6month' ? "active" : ""}>6m</li>
                                                                <li onClick={() => this.changefilter('all_time')} className={this.state.most_likes == 'all_time' ? "active" : ""}>All Time</li>
                                                            </ul>
                                                        </div>
                                                        <div className="popular-prod-name-filter">
                                                            <ul className="popular-product-lisitng-filter">
                                                                <li onClick={() => this.changefilterbrand(2)} className={this.state.brand_id == 2 ? "active" : ""}>Nike</li>
                                                                <li onClick={() => this.changefilterbrand(1)} className={this.state.brand_id == 1 ? "active" : ""}>Jordan</li>
                                                                <li onClick={() => this.changefilterbrand(5)} className={this.state.brand_id == 5 ? "active" : ""}>adidas</li>
                                                                <li onClick={() => this.changefilterbrand(35)} className={this.state.brand_id == 35 ? "active" : ""}>Yeezy</li>
                                                                <li onClick={() => this.changefilterbrand(0)} className={this.state.brand_id == 0 ? "active" : ""}>View all</li>
                                                            </ul>
                                                        </div>
                                                        {this.state.width >= 768 ? <p>These rankings are based on the combined number of likes from Kicksfinder and Sneaker News since 2015.</p> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.width >= 768 ?
                                            <div className="releasedate-blk">
                                                <div id="product_listing" className="prod_list">
                                                    <ul className="allproducts">
                                                        {this.state.products.map((item, key) =>
                                                            productBlock(item, key)
                                                        )}
                                                    </ul>
                                                </div>
                                                {/* <ProductListing></ProductListing> */}
                                            </div>
                                            : ''}
                                        <div className="popular-prod-blk most-popular-products-main">
                                            <div className="popular-product-lisitng-wrap">
                                                {this.state.productssmall.map((subitem, subkey) =>
                                                    <>
                                                        <div className="popular-product-inner-lisitng">
                                                            {subitem.map((item, key) =>
                                                                productSmallBlock(item, key, subkey)
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                                {(0 === this.state.productssmall.length && this.state.isbuttonLoading) && (
                                                    <div className={"popular-product-inner-lisitng most-pop-placeholder is-loading"}>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">11</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 1</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">12</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 3</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">13</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 2</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">14</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 2</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">15</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 1</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">16</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 3</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">17</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 2</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">18</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 11</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">19</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 3</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        <div className="prod-list-new">
                                                            <div className="item-list-counter"><span className="item-number">20</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 15</span></div>
                                                            <div className="item-image product-placeholder"></div>
                                                            <div className="item-name product-placeholder"></div>
                                                        </div>
                                                        {this.state.width >= 767 && (
                                                            <Fragment>
                                                                <div className="prod-list-new">
                                                                    <div className="item-list-counter"><span className="item-number">21</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 1</span></div>
                                                                    <div className="item-image product-placeholder"></div>
                                                                    <div className="item-name product-placeholder"></div>
                                                                </div>
                                                                <div className="prod-list-new">
                                                                    <div className="item-list-counter"><span className="item-number">22</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 6</span></div>
                                                                    <div className="item-image product-placeholder"></div>
                                                                    <div className="item-name product-placeholder"></div>
                                                                </div>
                                                                <div className="prod-list-new">
                                                                    <div className="item-list-counter"><span className="item-number">23</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 2</span></div>
                                                                    <div className="item-image product-placeholder"></div>
                                                                    <div className="item-name product-placeholder"></div>
                                                                </div>
                                                                <div className="prod-list-new">
                                                                    <div className="item-list-counter"><span className="item-number">24</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 3</span></div>
                                                                    <div className="item-image product-placeholder"></div>
                                                                    <div className="item-name product-placeholder"></div>
                                                                </div>
                                                                <div className="prod-list-new">
                                                                    <div className="item-list-counter"><span className="item-number">25</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 2</span></div>
                                                                    <div className="item-image product-placeholder"></div>
                                                                    <div className="item-name product-placeholder"></div>
                                                                </div>
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            {this.state.totalLeft > 0 ?
                                                <div className="loadmore-btn">
                                                    <button onClick={() => this.getMoreProducts()} className={this.state.isbuttonLoading ? "load-more is-loading" : "load-more"}>{this.state.width <= 768 ? 'View More' : 'Load More'}<i className="fa fa-spinner loadicon"></i></button>
                                                </div> : ''}

                                            {this.state.width <= 768 ? (
                                                <i className="msp-rank-message">These rankings are based on the combined number of likes from Kicksfinder and Sneaker News since 2015.</i>
                                            ) : ''}
                                        </div>

                                    </div>
                                }
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        )
    }
}

export default MostPopular